import _ from 'lodash';
import './../sass/main.scss';

window.$ = window.jQuery = require('jquery');
window.bootstrap = require('bootstrap/dist/js/bootstrap.bundle.js');

require ('./typographer/jquery.typographer.min.pack.js');
require ('./typographer/hyph-pl.min.js');

require('slick-carousel');
require ('../../node_modules/slicknav/jquery.slicknav.js');

require('owl.carousel2');

window.initMainCarousel = function () {
	if(jQuery('.main-carousel').length > 0) {
		jQuery('.main-carousel-js').slick({
			autoplay: false,
			arrows: false,
			dots: true,
			slidesToShow: 4,
			slidesToScroll: 4,
			responsive: [
				{
					breakpoint: 1100,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 2
					}
				},
				{
					breakpoint: 767,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1
					}
				},
			]
		});
	}
}
window.initMainCarousel2 = function () {
	if(jQuery('.main-carousel').length > 0) {
		jQuery('.main-carousel-js-2').slick({
			autoplay: false,
			arrows: false,
			dots: true,
			slidesToShow: 5,
			slidesToScroll: 5,
			responsive: [
				{
					breakpoint: 1750,
					settings: {
						slidesToShow: 4,
						slidesToScroll: 4
					}
				},
				{
					breakpoint: 1400,
					settings: {
						slidesToShow: 3,
						slidesToScroll: 3
					}
				},
				{
					breakpoint: 1100,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 2
					}
				},
				{
					breakpoint: 767,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1
					}
				},
			]
		});
	}
}
window.initBoxesCarousel = function () {

	if(jQuery('.section-boxes-carousel').length > 0) {
		jQuery('.boxes').slick({
			autoplay: false,
			arrows: false,
			dots: true,
			slidesToShow: 4,
			slidesToScroll: 4,
			responsive: [
				{
					breakpoint: 1100,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 2
					}
				},
				{
					breakpoint: 767,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1
					}
				},
			]
		});
	}
}
window.initProductGallery = function () {
	jQuery('#productGallery').owlCarousel({
		loop:false,
		margin:10,
		nav:true,
		dot: false,
		responsiveClass:true,
		responsive:{
			0:{
				items:3
			},
			768:{
				items:4
			},
			992:{
				items:5
			}
		},
		onInitialized: function (evt) {
			let previewContainer = document.getElementById('productGalleryPreview');
			let thumbs = evt.target.querySelectorAll('.owl-item img');

			[].forEach.call(thumbs, function(thumb) {
				thumb.addEventListener("click", function(e) {
					[].forEach.call(thumbs, function(thumb) {
						thumb.parentElement.classList.remove('active');
					});
					this.parentElement.classList.add('active');
					previewContainer.src = this.dataset.medium;
					previewContainer.closest('a').href = this.dataset.big;
				});
			});
		}
	});
}
jQuery.noConflict();
jQuery(document).ready(function(){
	
	jQuery('body img').addClass('img-fluid');
	
	jQuery('body').typographer_orphan({
	  forbidden: ["a", "i", "o", "u", "w", "z", "oraz", "dla"]
	});
	
	var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'));
	var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
	  return new bootstrap.Popover(popoverTriggerEl)
	});
	
	var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
	var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
	  return new bootstrap.Tooltip(tooltipTriggerEl)
	});

	//var stickyNavTop = $('.nav').offset().top;
	var stickyNavTop = 140;
		   	
	// our function that decides weather the navigation bar should have "fixed" css position or not.
	var stickyNav = function(){
		var mainScrollTop = jQuery(window).scrollTop(); // our current vertical position from the top

		// if we've scrolled more than the navigation, change its position to fixed to stick to top,
		// otherwise change it back to relative
		if (mainScrollTop > stickyNavTop) { 
			jQuery('#top-one').addClass('main-top-sticky');
		} else {
			jQuery('#top-one').removeClass('main-top-sticky'); 
		}
	};

	// and run it again every time you scroll
	jQuery(window).scroll(function() {
		stickyNav();
	});

	jQuery("#menu-top").slicknav({
		prependTo: '#col-menu-top',
		label: '',
		closeOnClick: true,
		allowParentLinks: true,
	});

	window.initMainCarousel();
	window.initMainCarousel2();
	mainResize();
	
});

window.onload = function () {
	jQuery('#main-loader').remove();
};

jQuery(window).resize(function() {
	mainResize();
});


//Funkcje

function mainResize() {
	var wwidth = jQuery(window).width();
    if (wwidth <= 767) {
		
	} else {
		
	}
}

function placementObjectBefore(element, elementBefore) {
	jQuery(elementBefore).before(jQuery(element));
}

function placementObjectAfter(element, elementAfter) {
	jQuery(elementAfter).after(jQuery(element));
}

function placementObjectAppend(element, elementAppend) {
	jQuery(elementAppend).append(jQuery(element));
}

function placementObjectPrepend(element, elementPrepend) {
	jQuery(elementPrepend).prepend(jQuery(element));
}